import payload_plugin_3ojAJYsLjs from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_xkNYaWh646 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ug99t40Ukr from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_A8TcqtY0sJ from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_z9yUp3zoLT from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ObYYuTl594 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uz6mqlMTMS from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ziTxs0kRnZ from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_g3LwjtteWD from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/ppl/Documents/_/live-display/app/.nuxt/components.plugin.mjs";
import prefetch_client_F4mwAnVvR3 from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt@3.14.1592_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@_wsqy3ipdbnew7nhm2l34p6on2y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5386EEDCrG from "/Users/ppl/Documents/_/live-display/node_modules/.pnpm/nuxt-quasar-ui@2.1.7_@quasar+extras@1.16.13_magicast@0.3.5_quasar@2.17.4_rollup@4.26.0/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import airbrake_H5MbEqRsNX from "/Users/ppl/Documents/_/live-display/shared/plugins/airbrake.ts";
import firebase_MrRCoKgj2U from "/Users/ppl/Documents/_/live-display/shared/plugins/firebase.ts";
import luxon_VlqPBC7NBo from "/Users/ppl/Documents/_/live-display/shared/plugins/luxon.ts";
import route_xWBYLf55o4 from "/Users/ppl/Documents/_/live-display/shared/plugins/route.ts";
import store_9xNuDHGAVU from "/Users/ppl/Documents/_/live-display/app/plugins/store.ts";
export default [
  payload_plugin_3ojAJYsLjs,
  revive_payload_client_xkNYaWh646,
  unhead_Ug99t40Ukr,
  router_A8TcqtY0sJ,
  payload_client_z9yUp3zoLT,
  navigation_repaint_client_ObYYuTl594,
  check_outdated_build_client_uz6mqlMTMS,
  chunk_reload_client_ziTxs0kRnZ,
  plugin_vue3_g3LwjtteWD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_F4mwAnVvR3,
  plugin_5386EEDCrG,
  airbrake_H5MbEqRsNX,
  firebase_MrRCoKgj2U,
  luxon_VlqPBC7NBo,
  route_xWBYLf55o4,
  store_9xNuDHGAVU
]