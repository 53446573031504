// Initialize main stores
export default defineNuxtPlugin(async () => {
  const authenticationStore = useAuthenticationStore()
  const { authUser } = storeToRefs(authenticationStore)
  const { listenAuth } = authenticationStore

  const enterpriseStore = useEnterpriseStore()
  const { getEnterprise, resetEnterprise } = enterpriseStore

  const userStore = useUserStore()
  const { getUser, resetUsers } = userStore

  const teamStore = useTeamStore()
  const { getTeams, resetTeams } = teamStore

  const route = useRoute()

  const initStores = async () => {
    const enterpriseId = authUser.value?.claims?.enterpriseId

    if (!authUser.value || !enterpriseId) {
      return
    }

    const enterpriseData = await getEnterprise(enterpriseId)

    if (!enterpriseData) {
      return
    }

    const userId = authUser.value.uid
    const userData = await getUser(userId)

    if (!userData) {
      return
    }

    const teamIds = authUser.value.claims.teamIds

    if (!Array.isArray(teamIds)) {
      return
    }

    await getTeams(teamIds)
  }

  // Initialize stores as quickly as possible
  await initStores()

  watch(authUser, async userInfo => {
    if (!userInfo) {
      return
    }

    const enterpriseId = userInfo.claims?.enterpriseId

    if (!enterpriseId) {
      return
    }

    await initStores()
  })

  // Update stores according to authentication state
  listenAuth(async user => {
    if (user) {
      await initStores()
      return
    }

    resetEnterprise()
    resetUsers()
    resetTeams()

    if (isSamePath(route, '/sign-in')) {
      return
    }

    await navigateTo('/sign-in', {
      replace: true
    })
  })
})
