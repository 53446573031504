import { default as indexeaFGmSJwu8Meta } from "/Users/ppl/Documents/_/live-display/app/pages/account/index.vue?macro=true";
import { default as accountbWilSGkBzjMeta } from "/Users/ppl/Documents/_/live-display/app/pages/account.vue?macro=true";
import { default as indexMv9Tq9CorRMeta } from "/Users/ppl/Documents/_/live-display/app/pages/assets/index.vue?macro=true";
import { default as assetseTbeI1jH7GMeta } from "/Users/ppl/Documents/_/live-display/app/pages/assets.vue?macro=true";
import { default as indexiBi44SYKlfMeta } from "/Users/ppl/Documents/_/live-display/app/pages/index.vue?macro=true";
import { default as indexsvbJS3dB1GMeta } from "/Users/ppl/Documents/_/live-display/app/pages/invoices/index.vue?macro=true";
import { default as invoicesKSZCT9BmbdMeta } from "/Users/ppl/Documents/_/live-display/app/pages/invoices.vue?macro=true";
import { default as _91pageId_93O2kBWpNTFkMeta } from "/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId]/[pageId].vue?macro=true";
import { default as indexCyAB1A37IcMeta } from "/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId]/index.vue?macro=true";
import { default as _91playlistId_93Xe2CGixATjMeta } from "/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId].vue?macro=true";
import { default as indexB4UGrt6euNMeta } from "/Users/ppl/Documents/_/live-display/app/pages/playlists/index.vue?macro=true";
import { default as playlistsSEeLulxwsWMeta } from "/Users/ppl/Documents/_/live-display/app/pages/playlists.vue?macro=true";
import { default as _91screenId_93JO2PX2aybiMeta } from "/Users/ppl/Documents/_/live-display/app/pages/screens/[screenId].vue?macro=true";
import { default as indexpzsCjYvqOOMeta } from "/Users/ppl/Documents/_/live-display/app/pages/screens/index.vue?macro=true";
import { default as screensS9pc8TGrBiMeta } from "/Users/ppl/Documents/_/live-display/app/pages/screens.vue?macro=true";
import { default as sign_45inX000jYmd30Meta } from "/Users/ppl/Documents/_/live-display/app/pages/sign-in.vue?macro=true";
import { default as sign_45uprLzgsFA2bBMeta } from "/Users/ppl/Documents/_/live-display/app/pages/sign-up.vue?macro=true";
import { default as indexj6WiU2zVBnMeta } from "/Users/ppl/Documents/_/live-display/app/pages/support/index.vue?macro=true";
import { default as support5tiXnhGCwiMeta } from "/Users/ppl/Documents/_/live-display/app/pages/support.vue?macro=true";
import { default as _91teamId_93Meu08Sn1K8Meta } from "/Users/ppl/Documents/_/live-display/app/pages/teams/[teamId].vue?macro=true";
import { default as indexFKCN3h5Es5Meta } from "/Users/ppl/Documents/_/live-display/app/pages/teams/index.vue?macro=true";
import { default as teams402ri2WE0iMeta } from "/Users/ppl/Documents/_/live-display/app/pages/teams.vue?macro=true";
export default [
  {
    name: accountbWilSGkBzjMeta?.name,
    path: "/account",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/account.vue"),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/account/index.vue")
  }
]
  },
  {
    name: assetseTbeI1jH7GMeta?.name,
    path: "/assets",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/assets.vue"),
    children: [
  {
    name: "assets",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/assets/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexiBi44SYKlfMeta || {},
    redirect: "/playlists",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/index.vue")
  },
  {
    name: invoicesKSZCT9BmbdMeta?.name,
    path: "/invoices",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/invoices.vue"),
    children: [
  {
    name: "invoices",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/invoices/index.vue")
  }
]
  },
  {
    name: playlistsSEeLulxwsWMeta?.name,
    path: "/playlists",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/playlists.vue"),
    children: [
  {
    name: _91playlistId_93Xe2CGixATjMeta?.name,
    path: ":playlistId()",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId].vue"),
    children: [
  {
    name: "playlists-playlistId-pageId",
    path: ":pageId()",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId]/[pageId].vue")
  },
  {
    name: "playlists-playlistId",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/playlists/[playlistId]/index.vue")
  }
]
  },
  {
    name: "playlists",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/playlists/index.vue")
  }
]
  },
  {
    name: screensS9pc8TGrBiMeta?.name,
    path: "/screens",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/screens.vue"),
    children: [
  {
    name: "screens-screenId",
    path: ":screenId()",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/screens/[screenId].vue")
  },
  {
    name: "screens",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/screens/index.vue")
  }
]
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inX000jYmd30Meta || {},
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45uprLzgsFA2bBMeta || {},
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/sign-up.vue")
  },
  {
    name: support5tiXnhGCwiMeta?.name,
    path: "/support",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/support.vue"),
    children: [
  {
    name: "support",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/support/index.vue")
  }
]
  },
  {
    name: teams402ri2WE0iMeta?.name,
    path: "/teams",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/teams.vue"),
    children: [
  {
    name: "teams-teamId",
    path: ":teamId()",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/teams/[teamId].vue")
  },
  {
    name: "teams",
    path: "",
    component: () => import("/Users/ppl/Documents/_/live-display/app/pages/teams/index.vue")
  }
]
  }
]