// import _merge from 'lodash.merge'

import type { Document, DocumentFilter, DocumentFilterFn, DocumentInitialData } from '../types/database'
import type { AddToArrayOptions } from '../types/array'
import type { StoreOptions } from '../types/store'

type PartialDoc<T extends Document> = Pick<T, 'id'> & Partial<T>

const addToArrayOpts: AddToArrayOptions = {
  mainKey: 'id',
  sortKey: 'updationDate'
}

export function getDocumentFilter (documentFilter: DocumentFilter|DocumentFilterFn = {}): Required<DocumentFilter> {
  const docFilter = typeof documentFilter === 'function' ? documentFilter() : documentFilter

  return {
    where: [
      ...(docFilter.where || []),
      { fieldName: 'isDeleted', condition: '==', value: false },
    ],
    sort: [
      ...(docFilter.sort || []),
      { fieldName: 'updationDate', direction: 'desc' }
    ],
    paginate: {
      ...(docFilter.paginate || {}),
      maximum: 5
    }
  }
}

export function initStore <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, getInitialData } = storeOptions
  const { isLoading, isAuthenticated, currentDocument } = state

  return (data: Partial<T> = {}): T|null => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      const rawData = toDeepRaw(data)
      const initialData = getInitialData(rawData)

      currentDocument.initial = cloneObject(initialData)
      currentDocument.newest = cloneObject(initialData)

      return cloneObject(initialData)
    } catch (err) {
      const error = err as Error
      console.error('[initDoc] Error', error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function countDocs <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName, documentFilter } = storeOptions
  const { isLoading, isAuthenticated, database, totalDocuments } = state
  const docFilter = getDocumentFilter(documentFilter)

  return async (userFilter: Pick<DocumentFilter, 'where'> = {}): Promise<number|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      // Warning: Basic merge, it can have several similar filters
      const whereFilter = [
        ...docFilter.where,
        ...(userFilter.where || [])
      ]
      const filter: Pick<DocumentFilter, 'where'> = {
        where: whereFilter
      }
      const totalDocs = await countDocuments(
        database.value,
        collectionName,
        filter
      )

      if (typeof totalDocs !== 'number') {
        throw new Error('totalDocs is not a number')
      }

      totalDocuments.value = totalDocs

      return totalDocs
    } catch (err) {
      const error = err as Error
      console.error(`[countDocs] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function findDocs <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName, documentFilter } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    documents, localDocuments, totalDocuments, lastDocument
  } = state
  const docFilter = getDocumentFilter(documentFilter)

  return async (): Promise<T[]|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      await countDocs<T>(storeOptions)()

      if (
        typeof totalDocuments.value === 'number' &&
        Array.isArray(documents.value) &&
        documents.value.length >= totalDocuments.value
      ) {
        console.log(`[findDocs] All documents found (${collectionName})`)
        return null
      }

      if (lastDocument.value) {
        docFilter.paginate.startDoc = lastDocument.value
      }

      const foundDocs = await findDocuments<T>(
        database.value,
        collectionName,
        docFilter
      )

      if (!foundDocs) {
        throw new Error('foundDocs is empty')
      }

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      lastDocument.value = foundDocs.lastDocument

      addToArray(documents.value, foundDocs.documents, addToArrayOpts)

      if (localDocuments?.value) {
        const cloneDocs = cloneObject(foundDocs.documents)
        addToArray(localDocuments.value, cloneDocs, addToArrayOpts)
      }

      return cloneObject(foundDocs.documents)
    } catch (err) {
      const error = err as Error
      console.error(`[findDocs] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function getFirstDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isAuthenticated, database, currentDocument,
    documents, localDocuments
  } = state

  return async (docId?: string): Promise<T|null> => {
    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      if (docId) {
        const doc = await getDoc<T>(storeOptions)(docId)
        const rawDoc = toDeepRaw(doc)

        if (!rawDoc) {
          throw new Error('rawDoc is empty')
        }

        currentDocument.initial = cloneObject(rawDoc)
        currentDocument.newest = cloneObject(rawDoc)

        return cloneObject(rawDoc)
      }

      const firstDoc: T|null = documents.value?.[0] || localDocuments?.value?.[0] || null
      const rawFirstDoc = toDeepRaw(firstDoc)

      if (rawFirstDoc) {
        currentDocument.initial = cloneObject(rawFirstDoc)
        currentDocument.newest = cloneObject(rawFirstDoc)
      }

      return cloneObject(rawFirstDoc)
    } catch (err) {
      const error = err as Error
      console.error(`[getFirstDoc] Error (${collectionName})`, error)

      return null
    }
  }
}

export function getDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    currentDocument, documents, localDocuments
  } = state

  return async (docId: string): Promise<T|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      const localDoc: T|null = documents.value?.find(({ id }) => id === docId) || null

      if (localDoc) {
        const rawDoc = toDeepRaw(localDoc)

        currentDocument.initial = cloneObject(rawDoc)
        currentDocument.newest = cloneObject(rawDoc)

        return cloneObject(rawDoc)
      }

      const doc = await getDocument<T>(
        database.value,
        collectionName,
        docId
      )

      if (!doc) {
        throw new Error('doc is empty')
      }

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      addToArray(documents.value, doc, addToArrayOpts)

      if (localDocuments?.value) {
        const cloneDoc = cloneObject(doc)
        addToArray(localDocuments.value, cloneDoc, addToArrayOpts)
      }

      currentDocument.initial = cloneObject(doc)
      currentDocument.newest = cloneObject(doc)

      return cloneObject(doc)
    } catch (err) {
      const error = err as Error
      console.error(`[getDoc] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function getDocs <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    documents, localDocuments
  } = state

  return async (docIds: string[]): Promise<T[]|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      const localDocs: T[] = []

      for (const docId of docIds) {
        const localDoc: T|null = documents.value?.find(({ id }) => id === docId) || null

        if (!localDoc) {
          continue
        }

        localDocs.push(localDoc)
      }

      if (
        localDocs.length > 0 &&
        localDocs.length === docIds.length
      ) {
        const rawDocs = toDeepRaw(localDocs)
        return cloneObject(rawDocs)
      }

      const docs = await getDocuments<T>(
        database.value,
        collectionName,
        docIds
      )

      if (!docs) {
        throw new Error('docs is empty')
      }

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      addToArray(documents.value, docs, addToArrayOpts)

      if (localDocuments?.value) {
        const cloneDocs = cloneObject(docs)
        addToArray(localDocuments.value, cloneDocs, addToArrayOpts)
      }

      return cloneObject(docs)
    } catch (err) {
      const error = err as Error
      console.error(`[getDocs] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function addDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    currentDocument, documents, localDocuments, totalDocuments
  } = state

  return async (data: Partial<T>): Promise<string|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      const initialDatetime = getInitialDatetime()
      const rawData = toDeepRaw(data)
      const docData = {
        ...rawData,
        ...initialDatetime,
      } as DocumentInitialData<T>

      const cloneData = cloneObject(docData)

      const docId = await addDocument<DocumentInitialData<T>>(
        database.value,
        collectionName,
        cloneData
      )

      if (!docId) {
        throw new Error('docId is empty')
      }

      const doc = { id: docId, ...cloneData } as T

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      if (typeof totalDocuments.value === 'number') {
        totalDocuments.value += 1
      }

      addToArray(documents.value, doc, addToArrayOpts)

      if (localDocuments?.value) {
        const cloneDoc = cloneObject(doc)
        addToArray(localDocuments.value, cloneDoc, addToArrayOpts)
      }

      currentDocument.initial = cloneObject(doc)
      currentDocument.newest = cloneObject(doc)

      return docId
    } catch (err) {
      const error = err as Error
      console.error(`[addDoc] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function updateDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    currentDocument, documents, localDocuments
  } = state

  return async (doc: T): Promise<string|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      const isoDatetime = getCurrIsoDatetime()
      const rawDoc = toDeepRaw(doc)
      const cloneDoc = cloneObject(rawDoc)

      cloneDoc.updationDate = isoDatetime

      // Warning: Only full document (no partial document)
      const docId = await updateDocument<T>(
        database.value,
        collectionName,
        cloneDoc
      )

      if (!docId) {
        throw new Error('docId is empty')
      }

      // Retrieve full document, because "doc" is actually partial
      // Warning: Probably retrieved from the local store
      // const fullDoc = await getDoc<T>(storeOptions)(docId)

      // if (!fullDoc) {
      //   throw new Error('fullDoc is empty')
      // }

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      // const mergeDoc = _merge(fullDoc, cloneDoc)

      addToArray(documents.value, cloneDoc, addToArrayOpts)

      if (localDocuments?.value) {
        const cloneMergeDoc = cloneObject(cloneDoc)
        addToArray(localDocuments.value, cloneMergeDoc, addToArrayOpts)
      }

      currentDocument.initial = cloneObject(cloneDoc)
      currentDocument.newest = cloneObject(cloneDoc)

      return docId
    } catch (err) {
      const error = err as Error
      console.error(`[getDocs] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function deleteDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, collectionName } = storeOptions
  const {
    isLoading, isAuthenticated, database,
    documents, localDocuments, totalDocuments
  } = state

  return async (documentId: string): Promise<string|null> => {
    isLoading.value = true

    try {
      if (!isAuthenticated.value) {
        throw new Error('user is not authenticated')
      }

      if (!database.value) {
        throw new Error('database is empty')
      }

      const docId = await softDeleteDocument<T>(
        database.value,
        collectionName,
        documentId
      )

      if (!docId) {
        throw new Error('docId is empty')
      }

      if (documents.value === null) {
        documents.value = []
      }

      if (localDocuments?.value === null) {
        localDocuments.value = []
      }

      const docIndex = documents.value.findIndex(doc => doc.id === docId)

      if (docIndex !== -1) {
        documents.value.splice(docIndex, 1)
      }

      if (localDocuments?.value) {
        const localDocIndex = localDocuments.value.findIndex(doc => doc.id === docId)

        if (localDocIndex !== -1) {
          localDocuments.value.splice(localDocIndex, 1)
        }
      }

      if (
        docIndex !== -1 &&
        typeof totalDocuments.value === 'number' &&
        totalDocuments.value > 0
      ) {
        totalDocuments.value -= 1
      }

      return docId
    } catch (err) {
      const error = err as Error
      console.error(`[deleteDoc] Error (${collectionName})`, error)

      return null
    } finally {
      isLoading.value = false
    }
  }
}

export function resetCurrentDoc <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, getInitialData } = storeOptions
  const { currentDocument } = state

  return (data: Partial<T> = {}): void => {
    const rawData = toDeepRaw(data)
    const initialData = getInitialData(rawData)

    currentDocument.initial = cloneObject(initialData)
    currentDocument.newest = cloneObject(initialData)
  }
}

export function resetStore <T extends Document>(storeOptions: StoreOptions<T>) {
  const { state, getInitialData } = storeOptions
  const {
    localDocuments, documents, currentDocument,
    totalDocuments, lastDocument, isLoading
  } = state

  return (data: Partial<T> = {}): void => {
    const rawData = toDeepRaw(data)
    const initialData = getInitialData(rawData)

    if (localDocuments?.value) {
      localDocuments.value = null
    }

    documents.value = null
    currentDocument.initial = cloneObject(initialData)
    currentDocument.newest = cloneObject(initialData)

    totalDocuments.value = null
    lastDocument.value = null

    isLoading.value = false
  }
}

export function getStoreActions <T extends Document>(storeOptions: StoreOptions<T>) {
  return {
    initStore: initStore<T>(storeOptions),
    countDocs: countDocs<T>(storeOptions),
    findDocs: findDocs<T>(storeOptions),
    getFirstDoc: getFirstDoc<T>(storeOptions),
    getDoc: getDoc<T>(storeOptions),
    getDocs: getDocs<T>(storeOptions),
    addDoc: addDoc<T>(storeOptions),
    updateDoc: updateDoc<PartialDoc<T>>(storeOptions),
    deleteDoc: deleteDoc<T>(storeOptions),
    resetCurrentDoc: resetCurrentDoc<T>(storeOptions),
    resetStore: resetStore<T>(storeOptions)
  }
}
